@import url("//fonts.googleapis.com/css?family=Raleway:400,300,500,600");
/* ==========================================================================

   Table Of Content
   ==========================================================================


   1.General
   2.Hero section
   3.Navbar
   4.Play button
   5.Featured on
   6.Benefits
   7.Features
   8.video tour
   9.tour
   10.Pricing
   11.reviews
   12.cta section
   13.Team
   14.Twitter feed
   15.Contact us
   16.Subscription form styles
   17.site footer
   18.Social
   19.Small devices (tablets, 768px and up)
   20.Medium devices (desktops, 992px and up)

*/
/* ==========================================================================
   1.General
   ========================================================================== */
body {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  overflow-x: hidden;
}

p {
  font-weight: 400;
  color: #4c4c4c;
  font-size: 17px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway;
  font-weight: 600;
  color: #4c4c4c;
}

h4 {
  font-size: 20px;
  margin: 12px 0;
}

a {
  color: #1e4eb6;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
  color: #1e4eb6;
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

b,
strong {
  font-weight: 600;
}

.growth {
  border-bottom: 2.5px solid #1e4eb6;
}

.white-popup {
  position: relative;
  background: #fff;
  padding: 20px;
  width: auto;
  max-width: 600px;
  margin: 20px auto;
}

.green {
  color: #34b199;
}

/* ==========================================================================
   2.Hero section
   ========================================================================== */
.hero-section {
  background: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/hub_content_background_small.png) center
    center no-repeat scroll;
  background-size: cover;
  padding: 0 0 4.5em;
  position: relative;
  color: #fff;
}

.hero-section::before,
.video-tour::before,
.pricing::before,
.twitter-feed::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.hero-section::before {
  background: rgba(0, 0, 0, 0.3);
}

.welcome-message {
  margin-top: 4.5em;
}

.welcome-message h1 {
  font-size: 36px;
  color: #fff;
  font-weight: 400;
  margin: 0 0 24px;
}

.welcome-message h2 {
  font-size: 22px;
  color: #fff;
  font-weight: 400;
  margin: 0;
}

.btn {
  padding: 1em 3em;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  background-color: #00a2e4;
  line-height: 1.5;
  border: none;
  border-radius: 3px;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  color: #fff;
  background-color: #1b469e;
}

.btn:active,
.btn:focus,
.btn:active:hover,
.btn:active:focus {
  color: #fff;
  outline: none;
  background-color: #1b469e;
}

.cta-btn p {
  color: #fff;
  margin: .75em 0 0;
  font-size: 18px;
}

/* ==========================================================================
   3.Navbar
   ========================================================================== */
.navbar-default {
  background-color: rgba(255, 255, 255, 0.97);
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  padding: .75em 0;
}

.nav > li {
  text-align: center;
}

.nav > li > a > i {
  padding-right: .2em;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #00a2e4;
  transition: all 0.3s ease-in-out;
}

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
  background-color: #fff;
}

.navbar-brand {
  padding: 0 0 0 1em;
}

.navbar-brand .logo-nav,
.nav-left {
  display: none;
}

.navbar-right {
  color: 'white';
}

.navbar-default.stuck .logo-head {
  display: none;
}

.navbar-default.stuck .logo-nav,
.navbar-default.stuck .nav-left {
  display: block;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #fff;
}

.navbar-default.stuck .navbar-nav > li > a {
  color: #4c4c4c;
}

.navbar-default.stuck .navbar-nav > li > a:hover,
.navbar-default.stuck .navbar-nav > li > a:focus {
  color: #1e4eb6;
}

.navbar-default .btn {
  font-size: 16px;
}

.navbar-default.stuck .navbar-nav .btn {
  display: inline-block;
  background: #1e51e2;
  color: #fff;
  border: 1px solid #1e51e2;
}

.navbar-default.stuck .navbar-nav .btn:hover,
.navbar-default.stuck .navbar-nav .btn:focus {
  background: #1b469e !important;
  color: #fff;
}

.navbar-default.stuck {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0 1px 12px 0px rgba(51, 51, 51, 0.23);
  -webkit-animation: fadeInDown 1s both;
  animation: fadeInDown 1s both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
/* ==========================================================================
   4.Play button
   ========================================================================== */
.play-btn {
  margin: 2.25em 0;
}

.hero-section .play-btn img {
  transition: opacity 0.3s linear;
}

.play-btn img:hover {
  opacity: .8;
}

/* ==========================================================================
   5.Featured on
   ========================================================================== */
.section-header {
  margin-bottom: 3em;
}

.section-header h2 {
  font-size: 24px;
  font-weight: 600;
  margin-top: 0;
}

.section-header h3 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

.featured-on,
.features,
.tour,
.site-footer {
  background-color: rgba(0, 0, 0, 0.03);
}

.section-spacing,
.section-spacing.featured-on,
.section-spacing.tour,
.section-spacing.site-footer {
  padding: 3.75em 0;
}

.featured-on .section-header {
  margin-bottom: 1.5em;
}

.featured-sites li {
  padding: 0 3em 1.5em;
}

.featured-sites li:last-child {
  padding-bottom: 0;
}

.featured-sites img {
  opacity: .15;
  transition: all 0.5s linear;
}

.featured-sites img:hover {
  opacity: .2;
}

/* ==========================================================================
   6.Benefits
   ========================================================================== */
.benefits img {
  margin-bottom: .75em;
}

.benefits p {
  color: #8a8a8a;
}

.benefits div[class^="col-"]:nth-child(1),
.benefits div[class^="col-"]:nth-child(2),
.reviews div[class^="col-"]:nth-child(1),
.reviews div[class^="col-"]:nth-child(2),
.team div[class^="col-"]:nth-child(1),
.team div[class^="col-"]:nth-child(2) {
  padding-bottom: 1.5em;
}

.benefits div[class^="col-"] > p:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   7.Features
   ========================================================================== */
.features {
  overflow-x: hidden;
}

.features div[class="row"]:nth-child(1),
.features div[class="row"]:nth-child(2) {
  margin-bottom: 3em;
}

.features article {
  margin-top: 1.5em;
}

.features article h2 {
  font-size: 24px;
  margin: 24px 0 12px;
}

.features article li {
  font-size: 17px;
  color: #8a8a8a;
  padding-left: 1em;
  text-indent: -.7em;
  padding-bottom: .2em;
}

.features article li:last-child {
  padding-bottom: 0;
}

.features article li:before {
  content: "• ";
  color: #00a2e4;
  padding-right: .5em;
}

.features div[class="row"]:nth-child(3) .col-md-4 article {
  margin-bottom: 1.5em;
}

/* ==========================================================================
   8.video tour
   ========================================================================== */
.video-tour {
  position: relative;
  height: 400px;
  overflow: hidden;
  background: #333 url(https://findbob-production.s3-us-west-2.amazonaws.com/images/enterprise/poster.jpg) center center
    no-repeat scroll;
  background-size: cover;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.video-tour::before {
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.video-tour h2 {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
}

#bgvid {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.video-tour .play-btn {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  margin: 0;
}

/* ==========================================================================
   9.tour
   ========================================================================== */
.center-block {
  float: none;
}

.tour h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.tour .btn {
  margin-top: 1em;
  vertical-align: initial;
}

.tour .btn i,
.sub-form .btn i {
  padding-left: .5em;
}

/* ==========================================================================
   10.Pricing
   ========================================================================== */
.pricing {
  background: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/enterprise/bg-pricing.jpg) center center
    no-repeat scroll;
  background-size: cover;
  position: relative;
}

.pricing::before {
  background: rgba(0, 0, 0, 0.3);
}

.pricing .section-header h2,
.pricing .section-header h3 {
  position: relative;
  color: #fff;
}

.plan {
  background-color: #fff;
  width: 350px;
  display: inline-block;
  margin: 0 0 1.5em;
  max-width: 100%;
}

.plan:last-child {
  margin-bottom: 0;
}

.plan .header,
.price,
.plan-features {
  padding: 1.5em 1em;
}

.plan .header h4 {
  font-size: 21px;
  margin: 0;
}

.price {
  background-color: #34b199;
  color: #fff;
}

.price-amount {
  font-size: 110px;
  line-height: 1;
}

.period {
  font-size: 30px;
}

.currency {
  font-size: 30px;
  vertical-align: top;
  top: 30px;
}

.plan-features li {
  padding-bottom: .75em;
  font-size: 17px;
}

.plan-features li:last-child {
  padding-bottom: 0;
}

.plan-features li span {
  font-weight: 700;
}

.plan-features li i {
  padding-left: .5em;
  color: #1e51e2;
}

.buy-button {
  padding-bottom: 1.5em;
}

.buy-button p {
  margin: 12px 0 0;
  font-weight: 300;
}

/* ==========================================================================
  11.reviews
   ========================================================================== */
.reviews figure {
  margin-bottom: 1.5em;
}

blockquote {
  padding: 0 0 0 3em;
  margin: 0;
  border-left: none;
  position: relative;
}

blockquote::before {
  content: "\201C";
  color: #e9e9e9;
  position: absolute;
  left: -12px;
  top: -50px;
  font-size: 128px;
}

blockquote p {
  font-size: 18px;
  font-weight: 400;
}

blockquote cite {
  font-size: 17.5px;
  font-weight: 400;
  color: #aeafaf;
  font-style: normal;
}

/* ==========================================================================
  12.cta section
   ========================================================================== */
.cta-section {
  background-color: #00a2e4;
}

.cta-section .section-header {
  margin-bottom: 2.25em;
}

.cta-section h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.cta-section .section-header h3 {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.cta-section .btn {
  background-color: #fff;
  color: #00a2e4;
  border: 1px solid transparent;
}

.cta-section .btn:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.cta-section p {
  color: #fff;
  margin: .75em 0 0;
}

.cta-section a[href^="tel"] {
  color: #fff;
}

.cta-section a[href^="tel"]:hover {
  color: #fff;
  text-decoration: underline;
}

/* ==========================================================================
  how it works
   ========================================================================== */
.hiw-section {
  background-color: #00a2e4;
}

.hiw-section .section-header {
  margin-bottom: 2.25em;
}

.hiw-section h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.hiw-section .section-header h3 {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}

.hiw-section .btn {
  background-color: #fff;
  color: #00a2e4;
  border: 1px solid transparent;
}

.hiw-section .btn:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.hiw-section p {
  color: #fff;
  margin: .75em 0 0;
}

.hiw-section a[href^="tel"] {
  color: #fff;
}

.hiw-section a[href^="tel"]:hover {
  color: #fff;
  text-decoration: underline;
}

/* ==========================================================================
   13.Team
   ========================================================================== */
.team h4 {
  font-size: 21px;
  margin: 24px 0 6px;
}

.team h5 {
  font-size: 19px;
  font-weight: 400;
  margin-top: 0;
}

.team div[class^="col-"]:nth-child(3) .team-info h5 {
  margin: 0;
}

.team p {
  color: #fff;
}

.team-details figure {
  position: relative;
  overflow: hidden;
  width: 360px;
  max-width: 100%;
  margin: 0 auto;
}

.team-details figcaption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(245, 99, 99, 0.95);
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  padding: 1.5em 1em;
  display: block;
}

.team-details figure figcaption div p,
.team-details figure figcaption div li a {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 200%, 0);
  -ms-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0);
}

.team-details figure:hover figcaption,
.team-details figure:hover figcaption div p,
.team-details figure:hover figcaption div li a {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.team-details figure figcaption div p {
  transition-delay: 0.05s;
}

.team-details figure figcaption div li:nth-child(1) a {
  transition-delay: 0.1s;
}

.team-details figure figcaption div li:nth-child(2) a {
  transition-delay: 0.15s;
}

.team-details .social {
  margin-top: .75em;
}

.team-details .social li a {
  color: #1e51e2;
  background-color: #fff;
  border: 1px solid #1e51e2;
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.5em;
}

.team-details .social li a:hover {
  color: #fff;
  background-color: #1e51e2;
  border: 1px solid #fff;
}

.team-details figcaption div {
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 1.5em;
}

/* ==========================================================================
   14.Twitter feed
   ========================================================================== */
.twitter-feed {
  background: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/enterprise/bg-twitter.jpg) center center
    no-repeat scroll;
  background-size: cover;
  position: relative;
}

.twitter-feed::before {
  background: rgba(0, 0, 0, 0.38);
}

.twitter-icon .fa-twitter {
  color: #fff;
  font-size: 4em;
  padding-bottom: 12px;
}

.tweet {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
}

.tweet a {
  color: #fff;
}

.tweet a:hover {
  color: rgba(255, 255, 255, 0.92);
}

/* ==========================================================================
   15.Contact us
   ========================================================================== */
.contact.section-spacing {
  padding: 3.75em 0 0;
}

#map-canvas {
  width: 100%;
  margin: 0px;
  padding: 0px;
  height: 35em;
}

#map-canvas img {
  max-width: none;
}

.map-info-box {
  text-align: left;
}

.map-info-box p {
  margin: 0 0 8px;
}

.map-info-box i {
  padding-right: .5em;
  color: #cec9c9;
}

.map-head h3 {
  height: 30px;
  background: url(https://findbob-production.s3-us-west-2.amazonaws.com/images/enterprise/enterprise-logo-nav.png) 47px
    center no-repeat;
  background-size: 110px auto;
  text-indent: -999em;
  margin: 12px 0 14px;
}

.map-address {
  font-weight: 300;
}

.map-email {
  font-weight: 400;
}

/* ==========================================================================
   16.Subscription form styles
   ========================================================================== */
.form-control::-webkit-input-placeholder {
  color: #8a8a8a;
}

.form-control:-moz-placeholder {
  color: #8a8a8a;
}

.form-control::-moz-placeholder {
  color: #8a8a8a;
}

.form-control:-ms-input-placeholder {
  color: #8a8a8a;
}

.form-control {
  background-color: #fff;
  border: 1px solid #00a2e4;
  color: #4c4c4c;
  box-shadow: none;
  height: 60px;
  font-weight: 400;
  font-size: 16px;
  padding: 0 1.5em;
}

.form-control:focus {
  border-color: #1b469e;
  outline: 0;
  box-shadow: none;
}

.sub-form .btn {
  padding: 1em;
}

#mc-form .btn-default {
  height: 60px;
}

#mc-notification {
  margin: 0.75em 0 0;
  font-weight: 400;
  color: #8a8a8a;
  font-size: 17px;
}

#error_explanation {
  color: #ff4c4c;
}
#error_explanation ul {
  color: #ff4c4c;
  margin: 0 0 30px 0;
}

.error {
  color: #ff4c4c !important;
}

.valid {
  color: #4caf50 !important;
}

.error i,
.valid i {
  padding-right: .5em;
}

/* ==========================================================================
   17.site footer
   ========================================================================== */
.site-footer small {
  display: inline-block;
}

.footer-links,
.site-footer small {
  margin: 0 0 .75em;
}

.site-footer small,
.footer-links a {
  color: #8a8a8a;
  font-weight: 300;
  font-size: 18px;
}

.footer-links a:hover {
  color: #1e4eb6;
}

.footer-links a:first-child {
  padding-right: .4em;
}

/* ==========================================================================
   18.Social
   ========================================================================== */
.social li {
  display: inline-block;
  padding-right: .5em;
  text-align: center;
}

.social li:last-child {
  padding-right: 0;
}

.social li a {
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  border-radius: 50%;
  position: relative;
  transition: all .8s ease;
  font-size: 1.3em;
  background: #d6d6d6;
}

.social li a:hover {
  color: #fff;
  background: #00a2e4;
}

.row {
  min-height: 500px;
}

/* ==========================================================================
   19.Small devices (tablets, 768px and up)
   ========================================================================== */
@media (min-width: 768px) {
  .hero-section {
    padding: 0 0 7.5em;
  }

  .welcome-message {
    margin-top: 8.25em;
  }

  .welcome-message h1 {
    font-size: 44px;
  }

  .welcome-message h2 {
    font-size: 23px;
  }

  .play-btn {
    margin: 3em 0;
  }

  .featured-sites li {
    padding: 0 1em;
    display: inline-block;
  }

  .section-spacing {
    padding: 4.5em 0;
  }

  .contact.section-spacing {
    padding: 4.5em 0 0;
  }

  .section-header h3 {
    font-size: 26px;
  }

  .benefits div[class^="col-"]:nth-child(1),
  .benefits div[class^="col-"]:nth-child(2),
  .reviews div[class^="col-"]:nth-child(1),
  .reviews div[class^="col-"]:nth-child(2),
  .team div[class^="col-"]:nth-child(1),
  .team div[class^="col-"]:nth-child(2) {
    padding-bottom: 0;
  }

  .tour .btn {
    margin: 0 0 0 1em;
  }

  .video-tour {
    height: 450px;
  }

  .plan {
    width: 325px;
    margin: 0 1em 0;
  }

  blockquote {
    padding: 0 0 0 1em;
  }

  blockquote::before {
    left: -18px;
    top: -30px;
    font-size: 80px;
  }

  .cta-section h2 {
    font-size: 46px;
  }

  .team h5 {
    margin: 0 !important;
  }

  .tweet {
    font-size: 23px;
  }

  .sub-form .btn {
    padding: 1em 2em;
  }
}
/* ==========================================================================
   20.Medium devices (desktops, 992px and up)
   ========================================================================== */
@media (min-width: 992px) {
  .hero-section {
    padding: 3em 0 12.75em;
  }

  .navbar-default {
    background-color: transparent;
  }

  .navbar-default .btn {
    border: 1px solid #fff;
    background-color: transparent;
    padding: .75em 1.5em;
  }

  .navbar-default .btn:hover {
    background-color: #1b469e !important;
  }

  .navbar-brand {
    padding: 0;
  }

  .nav-left {
    padding-left: 3em;
  }

  .welcome-message {
    margin-top: 4.5em;
  }

  .welcome-message h1 {
    font-size: 56px;
  }

  .welcome-message h2 {
    font-size: 24px;
  }

  .play-btn {
    margin: 4.5em 0;
  }

  .section-spacing {
    padding: 4.5em 0;
  }

  .contact.section-spacing {
    padding: 6em 0 0;
  }

  .features div[class="row"]:nth-child(1),
  .features div[class="row"]:nth-child(2) {
    margin-bottom: 7.5em;
  }

  .features article {
    margin-top: 6em;
  }

  .features img {
    max-width: none;
    position: absolute;
    width: 900px;
    top: 0;
    left: 22px;
  }

  .features div[class="row"]:nth-child(2) .col-md-7 img {
    left: -277px;
  }

  .featured-sites li {
    padding: 0 3em;
  }

  .video-tour {
    height: 600px;
    background: none;
  }

  .video-tour::before {
    background: rgba(0, 0, 0, 0.1);
  }

  #bgvid {
    display: block;
  }

  .plan {
    margin: 0 1.5em 0;
    width: 350px;
  }

  blockquote {
    padding: 0 0 0 3em;
  }

  blockquote::before {
    left: -5px;
    top: -50px;
    font-size: 128px;
  }

  .footer-links,
  .site-footer small {
    margin: 0;
  }

  .section-spacing.site-footer {
    padding: 3em 0;
  }
}
@media (max-width: 460px) {
  .video-tour .play-btn img {
    width: 45%;
  }
}
span {
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  padding: 16px 1.5em 12px;
  border: 1px solid #00a2e4;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0 5px;
  margin-right: 5px;
  margin-top: 2px;
  border: none;
  color: #fff;
  background: #00a2e4;
  border-radius: 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/* Magnific Popup CSS */
/* line 2, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80);
}

/* line 14, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

/* line 24, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 36, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

/* line 42, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-align-top .mfp-container:before {
  display: none;
}

/* line 45, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 9999;
}

/* line 53, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

/* line 57, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-ajax-cur {
  cursor: progress;
}

/* line 60, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

/* line 65, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

/* line 71, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

/* line 74, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* line 79, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-loading.mfp-figure {
  display: none;
}

/* line 82, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-hide {
  display: none !important;
}

/* line 85, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 9999;
}

/* line 95, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-preloader a {
  color: #ccc;
}

/* line 97, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-preloader a:hover {
  color: #fff;
}

/* line 100, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-s-ready .mfp-preloader {
  display: none;
}

/* line 103, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-s-error .mfp-content {
  display: none;
}

/* line 106, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 9999;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 118, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* line 122, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

/* line 138, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* line 141, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-close:active {
  top: 1px;
}

/* line 144, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-close-btn-in .mfp-close {
  color: #333;
}

/* line 147, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

/* line 154, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

/* line 163, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* line 174, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow:active {
  margin-top: -54px;
}

/* line 176, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* line 179, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

/* line 190, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow:after,
.mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

/* line 194, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow:before,
.mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

/* line 199, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow-left {
  left: 0;
}

/* line 201, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

/* line 204, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

/* line 208, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow-right {
  right: 0;
}

/* line 210, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

/* line 213, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
  border-left: 27px solid #3f3f3f;
}

/* line 216, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* line 219, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

/* line 223, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

/* line 226, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

/* line 231, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #ffffff;
}

/* Main image in popup */
/* line 242, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
/* line 255, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-figure {
  line-height: 0;
}

/* line 257, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

/* line 270, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

/* line 275, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-figure figure {
  margin: 0;
}

/* line 278, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

/* line 286, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

/* line 293, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-image-holder .mfp-content {
  max-width: 100%;
}

/* line 296, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  /* line 303, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  /* line 306, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  /* line 308, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  /* line 311, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  /* line 314, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  /* line 324, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  /* line 326, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  /* line 329, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  /* line 342, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  /* line 346, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  /* line 350, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  /* line 354, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* line 359, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-ie7 .mfp-img {
  padding: 0;
}

/* line 361, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px;
}

/* line 367, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-ie7 .mfp-container {
  padding: 0;
}

/* line 369, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-ie7 .mfp-content {
  padding-top: 44px;
}

/* line 371, /tmp/build_d364cb0eacbcc80855fef29787cc54dc/vendor/assets/stylesheets/magnific-popup.css */
.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}
